html,
body {
  font-style: normal;
}

.ant-switch-checked {
  background: #eb1c24 !important;
}
* {
  ::selection {
    color: #fff;
    background: #eb1c24 !important;
  }
}
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}

.ant-picker-time-panel {
  display: none !important;
}

.ant-table-body {
  overflow-y: hidden !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 0;
  height: 10px;
  background-color: #f5f5f5;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(85, 85, 85, 0.3);
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #d5d4d4;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(85, 85, 85, 0.3);
}

.ant-menu {
  padding-left: 1rem;
}

.ant-menu-vertical {
  padding-left: 0 !important;
}

.ant-menu-sub {
  background-color: #001529 !important;
}

.ant-menu-submenu-arrow {
  right: 25px !important;
}

.ant-pro-sider-logo {
  display: flex;
  justify-content: center;
  height: 64px;
  min-height: 3.25rem;
}

.ant-pro-sider-logo img {
  height: 3rem !important;
  vertical-align: middle;
}

.ant-menu-item-selected {
  width: 92% !important;
  //background-color: linear-gradient(269.55deg, #BD2648 0.43%, #EB1C24 100.84%);
  background: linear-gradient(269.55deg, #bd2648 0.43%, #eb1c24 100.84%) !important;
  border-radius: 8px;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

// @media (max-width: @screen-xs) {
//   .ant-table {
//     width: 100%;
//     overflow-x: auto;
//     &-thead > tr,
//     &-tbody > tr {
//       > th,
//       > td {
//         white-space: pre;
//         > span {
//           display: block;
//         }
//       }
//     }
//   }
// }
.ant-notification-notice {
  position: relative;
  top: 0px;
  right: 0px;
  width: 233px;
  height: 72px;
  margin: 0px;
  background-color: #52c41a;
}
.ant-notification-notice-content {
  top: 0;
  right: 0;
  width: 233px;
  height: 72px;
  margin: 0;
}
.ant-notification-notice-content {
  width: 185px;
  padding: 0;
  padding: 0px;
}
.ant-divider-horizontal {
  margin: 10px 0px;
}
// Compatible with IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

.ant-table-thead > tr > th {
  font-size: 13px;
  font-weight: bold !important;
  padding: 10px 15px;
  border: 1px solid #f0f0f0;
  height: 22px;
  // .ant-table-cell {
  //   display: flex;
  //   align-items: flex-start;
  // }
}
.ant-table-tbody > tr > td {
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid #f0f0f0;
  // height: 30px;
  line-height: 1;
}
.ant-table-cell {
  &::before {
    display: none;
  }
}
//.ant-layout-header{
//  height: 64px !important;
//  line-height: 64px !important;
//}
//
//.ant-pro-header-dark {
//  height: 64px !important;
//  width: calc(100% - 275px);
//  line-height: 64px !important;
//}
//
//.ant-pro-fixed-header {
//  height: 64px !important;
//  width: calc(100% - 275px);
//  line-height: 64px !important;
//}
//
//.ant-pro-fixed-header-action {
//  height: 64px !important;
//  width: calc(100% - 275px);
//  line-height: 64px !important;
//}
.pagination {
  margin-bottom: 15px;
  width: 100%;
  .pagination-total {
    color: #eb1c24;
    font-size: '0.95rem';
    font-weight: '550';
  }
}

.flex-center {
  display: flex;
  align-items: center;
  align-content: center;
}

.border-radius {
  &-4 {
    border-radius: 4px;
  }
  &-8 {
    border-radius: 8px;
  }
  &-12 {
    border-radius: 12px;
  }
}
.ant-modal-close-x {
  display: none;
}
.btn {
  &:hover {
    cursor: pointer;
  }
  &-add {
    color: white;
    background: linear-gradient(to Right, #bd2648 0.43%, #eb1c24 100.84%) !important;
    border-radius: 8px;
    &:hover,
    &:focus {
      color: #eb1c24 !important;
      background: white !important;
      border-color: #bd2648;
    }
  }
  &-minus {
    color: #eb1c24 !important;
    border-radius: 8px;
    background: white !important;
    border-color: #bd2648 !important;
    &:hover,
    &:active {
      background: linear-gradient(to Right, #bd2648 0.43%, #eb1c24 100.84%) !important;
      color: white !important;
      border-color: unset;
    }
  }
  &-noborder {
    color: #eb1c24 !important;
    border: none;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: #eb1c24 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #eb1c24;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #eb1c24;
}

.ant-upload-drag-icon,
.ant-upload-text {
  color: #b1b5c4 !important;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #eb1c24;
}

.ant-form-item {
  margin: 0 0 10px 0;
}

.price-prefix-input {
  height: 100%;
  background: #e6e8ec;
  color: #777e90;
  padding: 0 12px;
}

.form-popup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  &-text {
    position: relative;
    font-weight: bold;
    font-size: 20px;
    &:after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 80%;
      height: 2px;
      background: #bd2648;
    }
  }
}

.list-filter-dropdowm {
  width: 100%;
  .ant-select-selector {
    border-radius: 5px;
  }
}

@primary-color: #2979FF;@success-color: #00C853;@text-color: #081833;@disabled-color: #515560;@danger-color: #FF3D00;@font-size-base: 14px;