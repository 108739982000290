.custom-scroll-bar {
    overflow-y: auto;
}

.custom-scroll-bar::-webkit-scrollbar {
    display: none;
}

.active-menu {
    opacity: 1 !important;
    background: linear-gradient(269.55deg, #BD2648 0.43%, #EB1C24 100.84%);
    ;
}