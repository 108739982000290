.transaction-total-card-body {
  border-radius: 8px;
  overflow: hidden;
  .ant-card-body {
    height: 168px;
  }
  .transaction-total-card-title {
    color: #23262f;
    font-size: 14px;
    font-weight: 700;
  }
  .transaction-total-card-total {
    font-weight: 700;
    font-size: 40px;
    color: #23262f;
  }
  .transaction-total-card-percent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #777e90;
    &-value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;
      .ant-typography {
        color: #52c41a;
      }
    }
  }
  .transaction-total-card-changes {
    color: #f44336;
    width: 74px;
    height: 40px;
    .transaction-total-card-changes-body {
      border-radius: 8px;
      display: flex;
      align-items: center;
      align-content: center;
      background-color: #ffefee;
      width: 74px;
      height: 40px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

.transaction-detail-card-body {
  border-radius: 8px;
  overflow: hidden;
  .transaction-detail-card-title {
    font-weight: 600;
    font-size: 16px;
    color: #23262f;
  }
  .transaction-detail-card-content {
    margin-top: 15px;
    &-row {
      &:nth-child(2) {
        margin: 5px 0;
      }
    }
    &-value {
      font-weight: 700;
      font-size: 13px;
    }
  }
}

.transaction-table {
  background: #fcfcfd;
  border-radius: 8px;
  &-container {
    padding: 15px;
  }
  &-header {
    &-text {
      position: relative;
      font-weight: bold;
      font-size: 20px;
      &:after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 80%;
        height: 2px;
        background: #bd2648;
      }
    }
  }
}

.transaction-status {
  padding: 3px 10px;
  font-weight: 500;
  font-size: 12px;
  color: #fcfcfd;
  border-radius: 12px;
  display: inline-block;
  &-success {
    background: #52c41a;
  }

  &-pending {
    background: #CF9E0B;
  }

  &-fail {
    background: #f44336;
  }
}

@primary-color: #2979FF;@success-color: #00C853;@text-color: #081833;@disabled-color: #515560;@danger-color: #FF3D00;@font-size-base: 14px;