// .dashboard-card-container {
//   border-radius: 8px;
//   background-color: white;
//   padding: 10px;
//   cursor: pointer;
// }

// .schedule {
//   &-container {
//     background-color: white;
//     padding: 10px;
//     border-radius: 8px;
//   }
//   &-title {
//     background-color: inherit;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     &-color-revenue {
//       background-color: #FF884B;
//       border-radius: 4px;
//       width: 20px;
//       height: 25px;
//       margin-right: 10px;
//     }
//     &-color-quantity {
//       background-color: #28DF99;
//       border-radius: 4px;
//       width: 20px;
//       height: 25px;
//       margin-right: 10px;
//     }
//     &-more {
//       color: #1890FF;
//       &:hover {
//         cursor: pointer;
//       }
//     }
//     h5 {
//       margin: 0px;
//     }
//   }
//   &-content {
//     margin-top: 10px;
//   }
// }

// .header-filter-ticket{
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   margin-bottom: 20px;
// }
.ant-picker-input.ant-picker-input-active > input {
  color: black !important;
}
.ant-picker-input > input {
  color: black !important;
}

.ant-picker-cell-inner {
  width: unset !important;
}
.ant-picker-month-btn {
  display: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}
.slick-dots.slick-dots-bottom {
  display: none !important;
}

@primary-color: #2979FF;@success-color: #00C853;@text-color: #081833;@disabled-color: #515560;@danger-color: #FF3D00;@font-size-base: 14px;