@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.display-webkit {
  display: -webkit-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.purple-green-gradient {
  background: linear-gradient(90deg, #081da1 -0.01%, #f628fa 99.91%);
}

textarea:focus,
input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.form-input {
  display: block;
  width: 100%;
  height: 2.7rem;
  box-shadow: rgb(223 223 223) 0 0 3px 3px inset;
  padding: 10px;
  color: black;
  border: none;
  font-weight: 300;
}

.ant-pagination-prev > a {
  color: #eb1c24 !important;
}

.ant-pagination-next > a {
  color: #eb1c24 !important;
}

.ant-pagination-item {
  border-radius: 8px !important;
}

.ant-pagination-item > a {
  color: #000 !important;
}

.ant-pagination-item:hover {
  border-color: #eb1c24 !important;
}

.ant-pagination-item > a:hover {
  color: #eb1c24 !important;
}

.ant-pagination-item-active {
  background: linear-gradient(269.55deg, #bd2648 0.43%, #eb1c24 100.84%) !important;
  border-color: #eb1c24 !important;
}

.ant-pagination-item-active > a {
  color: #fff !important;
}

.ant-pagination-item-active > a:hover {
  color: #fff !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #eb1c24 !important;
}

.ant-pagination-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
}

/*.ant-table-tbody>tr>td>div {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

.ant-dropdown-menu-item {
  border: #6b6c6d;
  height: 40px;
  font-family: 'Montserrat', sans-serif;
}

.ant-dropdown-menu-item-active {
  background-color: #e3f5ff !important;
}

.ant-picker-time-panel {
  display: none !important;
}

.ant-table-body {
  overflow-y: hidden !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 0;
  height: 10px;
  background-color: #f5f5f5;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(85, 85, 85, 0.3);
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #d5d4d4;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(85, 85, 85, 0.3);
}

svg {
  vertical-align: baseline !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-lg {
  font-size: 14px !important;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 16/9 !important;
}
